import * as React from "react";
import { graphql, StaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Beach from "../components/BeachImage";
import Salt from "../components/Salt";
import MainLayout from "../components/MainLayout";
import Seo from "../components/Head";
import { H2, HtmlParagraph } from "../components/Utilities";
import Prefooter from "../components/Prefooter";
import PrimaryButton from "../components/PrimaryButton";
import Heading from "../components/Heading";
import SubpageNavigation from "../components/SubpageNavigation";

import Fish from "../images/home/dead-fish.jpg";
import Captain from "../images/home/captain.jpg";
import Booze from "../images/home/booze.jpg";
import Shells from "../images/home/shells.jpg";
import Boat from "../images/home/boat.jpg";
import Helm from "../images/home/helm.jpg";

const Index = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          allWpPage(filter: { title: { eq: "Homepage" } }) {
            edges {
              node {
                home {
                  heroSalt
                  heroTitle
                  introDescription
                  introTitle
                  links {
                    description
                    title
                    crew {
                      altText
                      sourceUrl
                    }
                    link {
                      target
                      title
                      url
                    }
                    sticker {
                      altText
                      sourceUrl
                    }
                  }
                  metaTitle
                  metaDescription
                }
                template {
                  ... on WpDefaultTemplate {
                    templateName
                    footerContent {
                      footerTitle
                      footerDescription
                      footerBackground {
                        altText
                        sourceUrl
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(props) => (
        <MainLayout>
          <Seo
            meta_title={props.allWpPage.edges[0].node.home.metaTitle}
            meta_title_backup={props.allWpPage.edges[0].node.home.heroTitle}
            meta_description={
              props.allWpPage.edges[0].node.home.metaDescription
            }
          />
          <section
            className="pt-10 lg:pt-[65px] max-w-screen-2xl mx-auto relative"
            id="hero"
          >
            <div className="relative z-10 h-[800px]">
              <img
                src={Fish}
                alt="Fish skeleton"
                className="absolute top-0 left-0 w-60 z-10 hidden sm:block sm:w-40 md:w-60"
              />
              <div className="absolute bottom-[200px] z-10 sm:z-0 md:z-10 sm:top-0 left-0 sm:left-40 md:left-52 w-24 sm:w-40">
                <StaticImage
                  src="../images/home/pint-glass.jpg"
                  alt="VSSL pint glass"
                />
              </div>
              <Beach />
              <img
                src={Captain}
                alt="Illustration of captain at wheel"
                className="absolute top-[350px] sm:top-10 lg:top-0 right-0 w-[375px] sm:w-[500px] lg:w-[700px]"
              />
              <img
                src={Booze}
                alt="Bottles of booze"
                className="absolute bottom-0 right-20 sm:right-40 lg:right-72 w-64 md:w-80"
              />
              <img
                src={Shells}
                alt="Shucked shells"
                className="absolute bottom-32 right-10 sm:right-20 md:right-28 w-36 sm:w-52"
              />
            </div>
            <div className="h-full absolute -top-40 sm:top-0 left-0 flex justify-center items-center text-center z-10 w-full px-8 lg:px-0">
              <div className="w-[850px]">
                <Heading
                  text={props.allWpPage.edges[0].node.home.heroTitle}
                  alignment={"justify-center"}
                />
              </div>
            </div>
            <div className="absolute bottom-20 right-10 md:right-28">
              <Salt
                title={props.allWpPage.edges[0].node.home.heroSalt}
                range={[0, 0.1]}
              />
            </div>
          </section>
          <section
            className="max-w-screen-xl mx-auto lg:mb-20 relative z-10"
            id="intro"
          >
            <div className="bg-bronze lg:p-20 grid lg:grid-cols-2 items-start px-8 py-14">
              <H2
                title={props.allWpPage.edges[0].node.home.introTitle}
                classes={"mb-5 lg:mb-0 lg:-translate-y-3"}
              />
              <HtmlParagraph
                content={props.allWpPage.edges[0].node.home.introDescription}
                classes={"text-ink"}
              />
            </div>
          </section>
          <section id="links" className="relative md:h-[4000px] md:py-40">
            <div className="relative max-w-screen-2xl mx-auto">
              <div className="sticky top-[40px] md:top-48 left-0">
                <StaticImage
                  src="../images/home/ships.jpg"
                  alt="Illustration of ships"
                  className="absolute -top-10 left-0 w-full sm:-top-32 md:top-0 xl:-top-5 md:w-[325px] lg:w-[500px]"
                />
                <img
                  src={Boat}
                  alt="Boat in ocean"
                  className="absolute top-24 sm:top-16 lg:top-32 left-16 sm:left-20 md:left-15 lg:left-32 w-[200px] md:w-[175px] lg:w-[250px]"
                />
                <img
                  src={Helm}
                  alt="Helm of boat"
                  className="absolute top-56 sm:top-52 lg:top-80 left-40 sm:left-36 md:left-52 w-[125px] sm:w-[150px] lg:w-[200px]"
                />
                <SubpageNavigation
                  images={props.allWpPage.edges[0].node.home.links}
                />
              </div>
              {props.allWpPage.edges[0].node.home.links.map((link, index) => {
                return (
                  <div
                    key={link.title}
                    className={`grid items-center md:grid-cols-2 max-w-screen-xl mx-auto px-8 2xl:px-0 ${
                      index === 0 ? "pt-48 sm:pt-0" : ""
                    }`}
                  >
                    <div className="h-[250px] md:h-[800px]"></div>
                    <div className="relative">
                      <H2 title={link.title} classes={"mb-6"} />
                      <HtmlParagraph
                        content={link.description}
                        classes={"text-smoke"}
                      />
                      <div className="w-full flex justify-end mt-4 sm:mt-8">
                        <PrimaryButton
                          text={link.link.title}
                          textDefaultX={77}
                          textHoverX={75}
                          linkType={
                            link.link.url.startsWith("http")
                              ? "external"
                              : "internal"
                          }
                          linkHref={link.link.url}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </section>

          <section className="mt-[300px] sm:mt-0">
            <Prefooter
              title={
                props.allWpPage.edges[0].node.template.footerContent.footerTitle
              }
              description={
                props.allWpPage.edges[0].node.template.footerContent
                  .footerDescription
              }
              background={
                props.allWpPage.edges[0].node.template.footerContent
                  .footerBackground
              }
            />
          </section>
        </MainLayout>
      )}
    />
  );
};

export default Index;
