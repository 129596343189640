import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { motion, useScroll, useTransform } from "framer-motion";
import loadable from "@loadable/component";

const isBrowser = typeof window !== "undefined";

const BeachComponent = () => {
  const { scrollYProgress } = useScroll();

  const beachTranslateY = useTransform(scrollYProgress, [0, 0.1], [100, -100], {
    clamp: true,
  });

  return (
    <motion.div
      style={{ translateY: beachTranslateY }}
      className="absolute bottom-0 left-0 w-[400px] opacity-40 hidden sm:block"
    >
      <StaticImage
        src="../images/home/beach.jpg"
        alt="People at beach bonfire"
      />
    </motion.div>
  );
};

const LoadableBeach = loadable(() =>
  isBrowser ? Promise.resolve(BeachComponent) : Promise.resolve(() => null)
);

export default function Beach() {
  return (
    <>
      <LoadableBeach />
    </>
  );
}
