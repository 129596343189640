import { useEffect } from "react";

export default function useCurseWords() {
  useEffect(() => {
    const curseWordSetting = localStorage.getItem("cursewords");
    const showCurseWords = (curseWords) => {
      const allCleanWords = Array.from(
        document.getElementsByClassName("clean")
      );
      const allCurseWords = Array.from(
        document.getElementsByClassName("curse")
      );
      if (curseWords) {
        allCleanWords.forEach((word) => {
          word.classList.add("hidden");
        });
        allCurseWords.forEach((word) => {
          word.classList.add("reveal");
        });
      } else {
        allCleanWords.forEach((word) => {
          word.classList.remove("hidden");
        });
        allCurseWords.forEach((word) => {
          word.classList.remove("reveal");
        });
      }
    };
    if (curseWordSetting !== null) {
      if (curseWordSetting === "on") {
        showCurseWords(true);
      } else {
        showCurseWords(false);
      }
    }
  }, []);
}
