import React, { useEffect, useState } from "react";
import useIsMobile from "../hooks/useIsMobile";
import useCurseWords from "../hooks/useCurseWords";

export default function SubpageNavigation(props) {
  const [scrollPosition, setScrollPosition] = useState(0);

  const visible = "visible opacity-100";
  const invisible = "invisible opacity-0";

  const [imageVisibility, setImageVisibility] = useState({
    theCrew: invisible,
    ourValues: invisible,
    aboutVssl: invisible,
    brandGuide: invisible,
  });

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  const isMobile = useIsMobile(576);

  const toggleCurseWords = () => {
    const curseWordSetting = localStorage.getItem("cursewords");
    const showCurseWords = (curseWords) => {
      const allCleanWords = Array.from(
        document.getElementsByClassName("clean")
      );
      const allCurseWords = Array.from(
        document.getElementsByClassName("curse")
      );
      if (curseWords) {
        allCleanWords.forEach((word) => {
          word.classList.add("hidden");
        });
        allCurseWords.forEach((word) => {
          word.classList.add("reveal");
        });
      } else {
        allCleanWords.forEach((word) => {
          word.classList.remove("hidden");
        });
        allCurseWords.forEach((word) => {
          word.classList.remove("reveal");
        });
      }
    };
    if (curseWordSetting === null) {
      localStorage.setItem("cursewords", "on");
      showCurseWords(true);
    } else {
      if (curseWordSetting === "on") {
        localStorage.setItem("cursewords", "off");
        showCurseWords(false);
      } else {
        localStorage.setItem("cursewords", "on");
        showCurseWords(true);
      }
    }
  };

  useCurseWords();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    if (scrollPosition > 0 && scrollPosition <= (isMobile ? 1600 : 1900)) {
      setImageVisibility({
        theCrew: visible,
        ourValues: invisible,
        aboutVssl: invisible,
        brandGuide: invisible,
      });
    } else if (
      scrollPosition > (isMobile ? 1600 : 1900) &&
      scrollPosition <= (isMobile ? 2100 : 2600)
    ) {
      setImageVisibility({
        theCrew: invisible,
        ourValues: visible,
        aboutVssl: invisible,
        brandGuide: invisible,
      });
    } else if (
      scrollPosition > (isMobile ? 2100 : 2600) &&
      scrollPosition <= (isMobile ? 2600 : 3400)
    ) {
      setImageVisibility({
        theCrew: invisible,
        ourValues: invisible,
        aboutVssl: visible,
        brandGuide: invisible,
      });
    } else if (
      scrollPosition > (isMobile ? 2600 : 3400) &&
      scrollPosition <= (isMobile ? 3100 : 4100)
    ) {
      setImageVisibility({
        theCrew: invisible,
        ourValues: invisible,
        aboutVssl: invisible,
        brandGuide: visible,
      });
    } else if (scrollPosition <= (isMobile ? 500 : 1200)) {
      setImageVisibility({
        theCrew: invisible,
        ourValues: invisible,
        aboutVssl: invisible,
        brandGuide: invisible,
      });
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition, isMobile]);

  return (
    <>
      {props.images.map((image, index) => {
        let imageClass = null;
        if (index === 0) {
          imageClass = imageVisibility.theCrew;
        } else if (index === 1) {
          imageClass = imageVisibility.ourValues;
        } else if (index === 2) {
          imageClass = imageVisibility.aboutVssl;
        } else if (index === 3) {
          imageClass = imageVisibility.brandGuide;
        }
        return (
          <div key={image.title}>
            {index === 0 ? (
              <button className="border-none" onClick={toggleCurseWords}>
                <img
                  src={image.sticker.sourceUrl}
                  alt={image.sticker.altText}
                  className={`absolute top-40 md:top-48 left-10 md:left-20 w-32 md:w-40 xl:w-48 -rotate-6 transition-all duration-300 ${imageClass}`}
                />
              </button>
            ) : (
              <img
                src={image.sticker.sourceUrl}
                alt={image.sticker.altText}
                className={`absolute top-40 md:top-48 left-10 md:left-20 w-32 md:w-40 xl:w-48 -rotate-6 transition-all duration-300 ${imageClass}`}
              />
            )}
            <img
              src={image.crew.sourceUrl}
              alt={image.crew.altText}
              className={`absolute top-[35px] xl:top-9 left-52 sm:left-60 w-40 xl:left-80 lg:w-48 xl:w-60 transition-all duration-300 ${imageClass}`}
            />
          </div>
        );
      })}
    </>
  );
}
